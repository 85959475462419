import axios from 'axios'
import VeiBanBridge from './bridge'
import { showFailToast, showToast } from 'vant'
import { getUrlKey } from './utils'


const service = axios.create({
  timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (!navigator.onLine) {
      showToast("当前网络不佳！")
      return Promise.reject(new Error('当前网络不佳！'))
    }
    let userInfo = localStorage.getItem('userInfo') == 'undefined' ? {} : JSON.parse(localStorage.getItem('userInfo'))
    let token = ''
    // 请求老平台接口
    if (config.url.indexOf('/classificationStatistics') > -1) {
      let oldToken = localStorage.getItem('oldToken')
      token = VeiBanBridge.isInVeiban() ? userInfo.token : oldToken
    } else {
      token = VeiBanBridge.isInVeiban() ? userInfo.newToken : getUrlKey('token')
    }
    if (token) {
      // localStorage.setItem('token', token)  //本地调试放开方便调试
      // 将token放到请求头发送给服务器,将tokenkey放在请求头中
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // //本地调试放开方便调试
    // else {
    //   let localToken = localStorage.getItem('token') || 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2tleSI6ImM3MmY5MGIxLTA1ZmUtNGYzMS1hYjNjLTY4Y2Q2OGUwZTVkYiIsInVzZXJfaWQiOjE2MywidXNlcm5hbWUiOiIxNTAxMzczNDIwOCJ9.bcAPbLUXNs1jRUJCeSl6AsQQzy6K8Se84sXDijhQ-sq3HT6kWKgcsQbtZuRIgVYmSH-vj8d-jSiR-_htTt9fuw'
    //   if (localToken) {
    //     config.headers['Authorization'] = 'Bearer ' + localToken
    //   }
    // }
    console.log('Tenant-Id===', userInfo.tenantId)
    if (userInfo.tenantId) {
      config.headers['Tenant-Id'] = userInfo.tenantId
    }
    let url = config.url
    if (config.method === 'get' && config.params) {
      url += '?'
      const keys = Object.keys(config.params)
      for (const key of keys) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`
      }
      url = url.substring(0, url.length - 1)
      config.params = {}
    }
    config.url = url

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 文件流
    if (response.config.responseType == 'arraybuffer' || response.config.responseType == 'blob') {
      return res
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.code !== 0) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        // ElMessageBox.confirm(
        //   'You have been logged out, you can cancel to stay on this page, or log in again',
        //   'Confirm logout',
        //   {
        //     confirmButtonText: 'Re-Login',
        //     cancelButtonText: 'Cancel',
        //     type: 'warning'
        //   }
        // ).then(() => { })
      } else if (res.code === 180000) {
        // 老平台 的 错误代码  不提示message
        return Promise.reject(res.message)
      } else if (res.code === 401) {
        // 去登录
      }
      showFailToast(res.msg)
    } else {
      if (response.config.url.indexOf('api/session/user/login') > -1) {
        return {
          ...res.data,
          token: response.headers['nobug-sid'] || ''
        }
      }
      return res
    }
  },
  error => {
    const msg = error.message
    if (msg.includes('timeout')) {
      showToast("请求超时！")
    }
    if (msg.includes('Network Error')) {
      showToast("当前网络不佳，请重试！")
    }

    // ElMessage({
    //   message: msg,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
